import React from "react"
import LayoutComponent from "../components/layout.component"
import { Header } from "../components/navbar.component"
import { Carousel } from "../components/carousel-banner.component"
import { graphql } from "gatsby"
import { Slices } from "../components/slices.component"
import { linkFragment } from "../link-resolver"


export const query = graphql`
query homePageQuery {
        prismic {
            allHomePages {
                edges {
                    node {
                        header_slides {
                            background_image
                            content
                        }
                        body {
                            ... on PRISMIC_HomePageBodyItems_collection {
                                label
                                type
                                primary {
                                    bg_color
                                    bg_image
                                    text
                                    title
                                    link_style
                                    link_text
                                }
                                fields {
                                    tag
                                }
                            }
                            ... on PRISMIC_HomePageBodyForm {
                                type
                                label
                                primary {
                                    bg_color
                                    bg_image
                                    form_script
                                    form_url
                                    text
                                    title
                                }
                            }
                            ... on PRISMIC_HomePageBodyFeature {
                                type
                                label
                                fields {
                                    image
                                    link {
                                       ...link
                                    }
                                    link_text
                                    link_style
                                    text
                                    title
                                }
                                primary {
                                    bg_color
                                    bg_image
                                    text
                                }
                            }
                            ... on PRISMIC_HomePageBodyBlock_with_text_and_image {
                                type
                                label
                                primary {
                                    bg_image
                                    bg_color
                                    min_height
                                    image
                                    link {
                                        ...link
                                    }
                                    link_style
                                    link_text
                                    text
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }
}

`

const IndexPage = ({data}) => {
  const page = data.prismic.allHomePages.edges[0].node
  return (
    <LayoutComponent>
      <Header/>
      <div className="carousel-top"><Carousel slides={page.header_slides}/></div>
      <Slices body={page.body} />
    </LayoutComponent>
  )
}

IndexPage.fragments = [linkFragment]

export default IndexPage

